
import { computed, ComputedRef, ref } from '@vue/reactivity';
import { Steps, Step, Button as AButton, notification } from 'ant-design-vue';
import { orderBy, isEmpty } from 'lodash-es';
import { storeToRefs } from 'pinia';
import { defineComponent, onMounted, Ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import * as Yup from 'yup';

import { LocalStorageEnum } from '@/enums/localStorageEnum';
import {
  IFormQuestion,
  IFormQuestionAnswer,
  IFormQuestionAnswerOption,
  IFormSection
} from '@/services/types';
import { useFormStore } from '@/store/modules/form';

import TheForm from '@/components/TheForm.vue';
import TheRadioInput from '@/components/TheRadioInput.vue';
import { IRadio } from '@/components/types';

interface ISavedAnswers {
  formId: string;
  sessionId: string;
  answerList: Array<IFormQuestionAnswer>;
}

export default defineComponent({
  components: {
    TheForm,
    Steps,
    Step,
    AButton,
    TheRadioInput
  },
  setup() {
    const store = useFormStore();
    const router = useRouter();
    const { t } = useI18n();

    const { checkup } = storeToRefs(store);
    // TO:DO Get UID from env
    const form_id = 'c693b3eb-eefb-43fb-8334-b73cec3d599a';
    let savedAnswers: Array<IFormQuestionAnswer> = [];

    // Is Form fetching?
    const formFetching: Ref<boolean> = ref(true);

    onMounted(async() => {
      if (isEmpty(store.session.id)) {
        router.push({ name: 'Information' });
      } else {
        formFetching.value = true;
        await store.fetchForm(form_id);

        if (!store.errors.find(error => error.method === 'fetchForm')) {
          let storagedAnswers: ISavedAnswers | string | null = localStorage.getItem(LocalStorageEnum.SavedAnswers);
          if (storagedAnswers && !isEmpty(storagedAnswers)) {
            storagedAnswers = JSON.parse(storagedAnswers) as ISavedAnswers;
            if (storagedAnswers.formId === form_id && storagedAnswers.sessionId === store.session.id) {
              savedAnswers = storagedAnswers.answerList;
              notification.success({
                message: t('checkup.saved-answers-loaded'),
                duration: 2.5
              });
            }
          }
        }

        formFetching.value = false;
      }
    });

    // Step current
    const currentStep: Ref<number> = ref(0);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const forms: Ref<Array<any>> = ref([]);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const questionRefs: Ref<Array<any>> = ref([]);

    // Check is form valid
    const isFormValid: ComputedRef<boolean> = computed(() => {
      if (isEmpty(forms.value)) {
        return false;
      }
      const filteredForms = forms.value.filter(x => !x?.meta.valid);
      return isEmpty(filteredForms);
    });

    // Create step array.
    const steps: ComputedRef<Array<IFormSection>> = computed(() => {
      if (isEmpty(checkup.value.sections)) {
        return [];
      }

      return orderBy(
        checkup.value.sections.map((section: IFormSection) => {
          return {
            ...section,
            questions: section.questions.map((question: IFormQuestion) => {
              return {
                ...question,
                checkupAnswerOptions: orderBy(question.checkupAnswerOptions, ['index'], ['asc']),
                selectedAnswer: savedAnswers.find(x => x.QuestionId === question.id)?.CheckupAnswerOptionId || ''
              } as IFormQuestion;
            })
          };
        }),
        ['index'],
        ['asc']
      );
    });

    // Return current step page items
    const pageSteps: ComputedRef<Array<IFormSection>> = computed(() => {
      if (isEmpty(checkup.value.sections)) {
        return [];
      }
      return steps.value.filter(section => section.pageIndex === currentStep.value);
    });

    // is last step?
    const isLastStep = computed(() => {
      if (!checkup.value.pageCount) {
        return false;
      }
      return (checkup.value.pageCount - 1) === currentStep.value;
    });

    // Returns answer options given question
    const getQuestionAnswerOptions = (
      options: Array<IFormQuestionAnswerOption>
    ): Array<IRadio> => {
      return options.map((option: IFormQuestionAnswerOption): IRadio => {
        return {
          value: option.id,
          text: option.textTranslation
        } as IRadio;
      });
    };

    // Submit things
    const isFormSubmitting: Ref<boolean> = ref(false);
    const next = async(pageIndex: number): Promise<void> => {
      if (!isFormValid.value && currentStep.value < pageIndex) {
        await questionRefs.value.forEach(async(question) => {
          await question.validate();
        });
        notification.error({
          message: t('error.an-error-occurred'),
          description: 'Lütfen kırmızı ile işaretlenmiş zorunlu soruları cevaplayınız.',
          duration: 2.5
        });
      } else {
        questionRefs.value = [];
        window.scrollTo(0, 0);
        forms.value = [];
        // Prepare answers payload
        const answerList: Array<IFormQuestionAnswer> = [];
        await steps.value.map((step) => {
          step.questions.map((question) => {
            answerList.push({
              CheckupAnswerOptionId: question.selectedAnswer,
              QuestionId: question.id,
              UserSessionId: store.session.id
            });
          });
        });

        // Save local storage
        localStorage.setItem(
          LocalStorageEnum.SavedAnswers,
          JSON.stringify({
            formId: form_id,
            sessionId: store.session.id,
            answerList
          } as ISavedAnswers)
        );

        if (checkup.value.pageCount !== pageIndex) {
          currentStep.value = pageIndex;
        } else {
          isFormSubmitting.value = true;
          // Post answers
          await store.submitAnswers(answerList);
          if (!store.errors.find(error => error.method === 'fetchForm')) {
            router.push({ name: 'CheckupCompleted', query: { sid: store.session.id }});
          } else {
            isFormSubmitting.value = false;
          }
        }
      }
    };

    return {
      // Variables
      store,
      steps,
      formFetching,
      forms,
      questionRefs,
      currentStep,
      pageSteps,
      isLastStep,
      formSubmitting: isFormSubmitting,
      checkup,
      // Methods
      getQuestionAnswerOptions,
      isFormValid,
      next,
      // Modules
      Yup
    };
  }
});
