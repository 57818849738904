
import { RadioGroup, Radio } from 'ant-design-vue';
import { useField } from 'vee-validate';
import { computed, defineComponent, PropType } from 'vue';

import { IRadio } from './types';

export default defineComponent({
  components: {
    RadioGroup,
    Radio
  },
  props: {
    value: {
      type: [String, Number],
      default: ''
    },
    options: {
      type: Array as PropType<Array<IRadio>>,
      required: true
    },
    name: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: true
    },
    rules: {
      type: Object,
      default: undefined
    },
    showErrors: {
      type: Boolean,
      default: true
    }
  },
  setup(props: any, { emit }: { emit: any }) {
    const {
      value: fieldValue,
      errorMessage,
      handleBlur,
      handleChange,
      meta,
      errors,
      validate
    } = useField(props.name, props.rules, {
      initialValue: props.value
    });
    const inputValue = computed({
      get: () => {
        return fieldValue.value;
      },
      set: (value) => {
        emit('update:value', value);
      }
    });
    return {
      handleChange,
      handleBlur,
      errorMessage,
      inputValue,
      meta,
      errors,
      validate
    };
  }
});
