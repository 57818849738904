import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-558d3246"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container px-8 md:px-4 py-10 mx-auto text-black" }
const _hoisted_2 = {
  key: 0,
  class: "font-bold text-lg"
}
const _hoisted_3 = { class: "font-bold text-black text-3xl w-full block text-center mb-10" }
const _hoisted_4 = { class: "block w-full" }
const _hoisted_5 = { class: "steps-content" }
const _hoisted_6 = { class: "font-medium text-xl w-full block text-center mb-4 text-black" }
const _hoisted_7 = { class: "questions-wrapper" }
const _hoisted_8 = { class: "flex justify-between" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Step = _resolveComponent("Step")!
  const _component_Steps = _resolveComponent("Steps")!
  const _component_the_radio_input = _resolveComponent("the-radio-input")!
  const _component_the_form = _resolveComponent("the-form")!
  const _component_a_button = _resolveComponent("a-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.formFetching)
      ? (_openBlock(), _createElementBlock("span", _hoisted_2, "Checkup Soruları Yükleniyor..."))
      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          _createElementVNode("h1", _hoisted_3, _toDisplayString(_ctx.store.checkup.name), 1),
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_Steps, {
              current: _ctx.currentStep,
              "onUpdate:current": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.currentStep) = $event)),
              direction: "horizontal",
              onChange: _cache[1] || (_cache[1] = (current) => _ctx.next(current))
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.checkup.pageCount, (page) => {
                  return (_openBlock(), _createBlock(_component_Step, {
                    key: page,
                    disabled: page - 1 > _ctx.currentStep && !(page - 2 === _ctx.currentStep && _ctx.isFormValid)
                  }, null, 8, ["disabled"]))
                }), 128))
              ]),
              _: 1
            }, 8, ["current"])
          ]),
          _createElementVNode("div", _hoisted_5, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.checkup.pageCount, (page) => {
              return (_openBlock(), _createElementBlock(_Fragment, { key: page }, [
                (_ctx.currentStep === page - 1)
                  ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.pageSteps, (step) => {
                      return (_openBlock(), _createBlock(_component_the_form, {
                        ref: (el) => { _ctx.forms[step.index] = el },
                        key: step.id,
                        submitting: _ctx.formSubmitting,
                        "hide-buttons": "",
                        class: "mt-10"
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("h2", _hoisted_6, _toDisplayString(step.name), 1),
                          _createElementVNode("div", _hoisted_7, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(step.questions, (question, qIndex) => {
                              return (_openBlock(), _createBlock(_component_the_radio_input, {
                                ref: (el) => { _ctx.questionRefs.push(el) },
                                key: question.id,
                                value: question.selectedAnswer,
                                "onUpdate:value": ($event: any) => ((question.selectedAnswer) = $event),
                                class: "question",
                                rules: _ctx.Yup.string().required(),
                                options: _ctx.getQuestionAnswerOptions(question.checkupAnswerOptions),
                                label: `${ step.pageIndex + 1 }.${ step.index + 1 }.${ qIndex + 1 }  ${ question.textTranslation }`,
                                name: question.id,
                                "show-errors": false
                              }, null, 8, ["value", "onUpdate:value", "rules", "options", "label", "name"]))
                            }), 128))
                          ])
                        ]),
                        _: 2
                      }, 1032, ["submitting"]))
                    }), 128))
                  : _createCommentVNode("", true)
              ], 64))
            }), 128)),
            _createElementVNode("div", _hoisted_8, [
              _createVNode(_component_a_button, {
                type: "default",
                disabled: _ctx.currentStep === 0,
                class: "mt-4 mr-4",
                onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.currentStep -= 1))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('button.previous')), 1)
                ]),
                _: 1
              }, 8, ["disabled"]),
              _createVNode(_component_a_button, {
                type: "primary",
                loading: _ctx.formSubmitting,
                class: "mt-4",
                onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.next(_ctx.currentStep + 1)))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t(!_ctx.isLastStep ? 'button.save-and-continue' : 'button.submit')), 1)
                ]),
                _: 1
              }, 8, ["loading"])
            ])
          ])
        ], 64))
  ]))
}