import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "radio-label-wrapper" }
const _hoisted_2 = ["for"]
const _hoisted_3 = { class: "radio-group-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Radio = _resolveComponent("Radio")!
  const _component_RadioGroup = _resolveComponent("RadioGroup")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass({ 'has-error': !!_ctx.errorMessage })
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("label", {
        for: _ctx.name,
        class: "text-white"
      }, _toDisplayString(_ctx.label), 9, _hoisted_2)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_RadioGroup, {
        id: _ctx.name,
        value: _ctx.inputValue,
        "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.inputValue) = $event)),
        name: _ctx.name,
        onChange: _cache[1] || (_cache[1] = ($event) => _ctx.handleChange($event.target.value))
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (radio) => {
            return (_openBlock(), _createBlock(_component_Radio, {
              key: radio.value,
              value: radio.value,
              class: _normalizeClass(["radio-input text-white capitalize", { 'radio-selected': _ctx.inputValue === radio.value }])
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(radio.text), 1)
              ]),
              _: 2
            }, 1032, ["value", "class"]))
          }), 128))
        ]),
        _: 1
      }, 8, ["id", "value", "name"])
    ]),
    (_ctx.showErrors)
      ? _withDirectives((_openBlock(), _createElementBlock("p", {
          key: 0,
          class: "help-message"
        }, _toDisplayString(_ctx.errorMessage), 513)), [
          [_vShow, _ctx.errorMessage && _ctx.meta.touched]
        ])
      : _createCommentVNode("", true)
  ], 2))
}